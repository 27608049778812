.logoImage {
  width: 6rem;
  position: absolute;
  top: 30px;
  right: 30px;
}
.page-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
  height: 100vh;
}

.login-vector {
  position: absolute;
}
.register-avatar {

  max-width: 670px;
  object-fit: contain;
  top: 60px;
  height: 100vh;
  position: absolute;
  right: 0;
}
