.sideBar {
  background-color: var(--Primary2);
  height: 100vh;
  position: fixed;
}
.sideMenuLogoImage {
  display: flex;
  width: 50%;
  margin: auto;
}
.sideMenuWrapper {
  margin: 10px 4px;
  color: white;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}
.sideMenuButtonContainer {
  display: flex;
  flex-direction: column;
  margin: 0px 8px;
}
.sideMenuButton {
  position: relative !important;
  display: flex;
  all: unset;
  cursor: pointer;
  color: white;
  border-radius: 8px;
  padding: 16px 40px;
}
.sideMenuIcon.close {
  position: absolute;
  right: 13px;
  padding: 10px;
  top: 7px;
}
.sideMenuTitle {
  padding: 16px 20px;
  font-weight: bold;
  letter-spacing: 1;
  letter-spacing: 0.2px;
}
.sideMenuIcon {
  padding-right: 10px;
}
.sideMenuButton.active {
  background-color: white;
  color: var(--Primary2);
  position: relative;
}
.logoutButtonContainer {
  display: flex;
  margin: 0px 16px;
  border-top: 1px solid #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.logoutButton {
  all: unset;
  cursor: pointer;
  width: 100%;
  padding: 20px 10px;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logoutSVG {
  width: 15px;
  height: 15px;
}
.requestsCountNumber {
  border-radius: 50%;
  background-color: #fbd34d;
  padding: 2px;
  width: 13px;
  height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 6px;
  font-size: 11px;
  color: var(--Shade);
}
