html,
body,
#root {
  height: 100%;
}
body,
p {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  margin: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --Primary: #0071c1;
  --Primary2: #0063b3;
  --c50: #f9fafa;
  --c100: #f4f4f6;
  --c200: #e6e7ea;
  --c300: #d2d5da;
  --c400: #9da3af;
  --c500: #6a7181;
  --c600: #4e545f;
  --c700: #3e434c;
  --Shade: #3f4d64;
  --Shade2: #1a5388;
  --Yellow: #fbd34d;
  --redBackground: #f1d2d2;
  --Warning: #b91c1c;
  --greenBackground: #d5f9e2;
  --darkGreenBackground: #0c7040;
  --greenText: #15803d;
  --secondaryBackground: #f6f6f6;
  --text-color: #565656;
  --acceptance: #15803d;
}
.subtext {
  color: var(--c500);
  font-size: 14px;
}
.welcome-text {
  color: var(--Shade);
  font-weight: 500;
  font-size: 26px;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
}
input[type="number"]:focus-visible,
input[type="text"]:focus-visible,
input[type="password"]:focus-visible
  .selectInputComponent:focus-visible
  textarea:focus-visible {
  outline: none;
  border: 1px solid var(--c300);
  border-radius: 8px;
}
input[type="text"],
input[type="password"] {
  padding: 0px 10px;
  background-color: #fff;
  color: #2b2828;
  height: 40px;
  outline: none;
  border: 1px solid var(--c300);
  border-radius: 8px;
}
.selectInputComponent {
  padding: 0px 10px;
  background-color: #fff;
  color: #2b2828;
  outline: none;
  border: 1px solid var(--c300);
  border-radius: 8px;
  height: 42px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url(./assets/images/arrowDown.svg);
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 18px;
}

.text-input {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.fullWidthButton {
  all: unset;
  display: block;
  cursor: pointer;
  background-color: var(--Primary);
  color: white;
  border-radius: 8px;
  width: 100%;
  padding: 15px 0px;
  text-align: center;
}
.fullWidthButton:disabled {
  all: unset;
  background-color: var(--c300);
  color: white;
  border-radius: 8px;
  width: 100%;
  padding: 15px 0px;
  text-align: center;
}
.forgetPassword {
  text-decoration: underline;
  cursor: pointer;
}
.forget-password-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  color: var(--c500);
  font-size: 13px;
}
.labelStyle {
  margin-bottom: -8px;
  z-index: 1;
  background-color: white;
  width: fit-content;
  margin-left: 10px;
  color: var(--c500);
  font-size: 13px;
}
.sideBar {
  width: 20vw;
}
.mainContainer {
  width: 100%;
  background-color: var(--secondaryBackground);
  margin-left: 20vw;
}
.headerWrapper {
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #f0f0f0;
}
.header {
  padding: 0 30px;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.primaryButton {
  all: unset;
  cursor: pointer;
  background-color: var(--Primary);
  color: white;
  border-radius: 8px;
  padding: 8px 30px;
}
.secondaryButton {
  all: unset;
  cursor: pointer;
  color: var(--Primary);
  border: 1px solid var(--Primary);
  border-radius: 8px;
  padding: 7px 29px;
}
.filterButton {
  all: unset;
  cursor: pointer;
  color: var(--c500);
  border: 1px solid var(--c500);
  border-radius: 8px;
  padding: 8px 25px;
}
.filterHeader {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
input[type="text"].searchInput {
  height: 40px;
  width: 230px;
  background-color: var(--c200);
  border-radius: 29px;
  border: none;
  color: var(--c400);
  padding-left: 40px;
}

.searchIcon {
  height: 40px;
  width: 18px;
  position: absolute;
  color: var(--c400);
  left: 13px;
}
.mainTable {
  margin: 20px 30px;
}
.tableHeaderContainer th {
  background-color: var(--Shade);
  color: white;
  font-weight: 600;
  height: 40px;
}
tr {
  background-color: white;
  text-align: center;
  height: 60px;
  border-bottom: 1px solid var(--c200);
}
table {
  border-collapse: collapse;
}
.active {
  all: unset;
  cursor: pointer;
  display: flex;
  justify-content: center;
  background: var(--greenBackground);
  border-radius: 8px;
  color: var(--greenText);
  padding: 7px 30px;
  width: 70px;
}
.blocked {
  all: unset;
  cursor: pointer;
  display: flex;
  justify-content: center;
  background: var(--redBackground);
  border-radius: 8px;
  color: var(--Warning);
  padding: 7px 30px;
  width: 70px;
}
.modalWrapper {
  z-index: 9999;
  position: fixed;
  background-color: #0000004f;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}
.modalContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.formContainer {
  position: relative;
  width: 350px;
  background-color: white;
  border-radius: 10px;
  text-align: start;
  display: flex;
  flex-direction: column;
  padding: 30px 25px;
  margin: auto;
}
.closeButton {
  all: unset;
  position: absolute;
  top: 5px;
  right: 15px;
  font-size: 23px;
  color: white;
}
textarea {
  padding: 10px 10px;
  background-color: #fff;
  color: #2b2828;
  height: 50px;
  outline: none;
  border: 1px solid var(--c300);
  border-radius: 8px;
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
}
.modalFormButtons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
}
.modalFormButtons > * {
  text-align: center;
  width: 50%;
}
.alertPopup {
  position: fixed;
  right: 20px;
  top: 80px;
  z-index: 99999;
  width: 400px;
}
.success {
  background-color: var(--darkGreenBackground);
  padding: 20px 50px;
  border-radius: 16px;
  color: white;
}
.alert {
  background-color: var(--Warning);
  padding: 20px 50px;
  border-radius: 16px;
  color: white;
}
.successIcon {
  position: absolute;
  width: 40px;
  top: -20px;
  left: 10px;
}
.uploadSectionContainer {
  background-color: var(--c50);
  padding: 30px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  border-radius: 8px;
  color: var(--c600);
}
.uploadSectionContainer p {
  margin-bottom: 5px;
}
.dragDropWrapper {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  margin-top: 20px;
}
.LeaveDropContainer,
.dragDropContainer {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.LeaveDropContainer:before {
  content: "";
  position: absolute;
  border: 5px dashed var(--c400);
  border-radius: 8px;
  top: -3px;
  bottom: -3px;
  left: -3px;
  right: -3px;
}
.dragDropContainer:before {
  content: "";
  position: absolute;
  border: 5px dashed var(--c700);
  border-radius: 8px;
  top: -3px;
  bottom: -3px;
  left: -3px;
  right: -3px;
}
.fileInput {
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.selectInputContainer {
  display: flex;
  align-items: center;
  height: 100%;
}
.selectInput {
  padding: 0px 5px;
  background-color: #fff;
  color: var(--c700);
  height: 30px;
  outline: none;
  border: 1px solid var(--c300);
  border-radius: 8px;
  width: 200px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url(./assets/images/arrowDown.svg);
  background-repeat: no-repeat;
  background-position-x: 94%;
  background-position-y: 12px;
}
.selectInput:focus-visible {
  outline: none;
  border: 1px solid var(--c300);
  border-radius: 8px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.toggleChecked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.toggleChecked + .slider {
  background-color: #15803d;
}

.toggleChecked + .slider {
  box-shadow: 0 0 1px #15803d;
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.submitPermissionButton {
  padding: 5px 0px;
  display: flex;
  justify-content: flex-end;
}
.requiredField {
  font-size: 13px;
  color: var(--Warning);
}
.actionButtonsContainer {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 30px;
  display: none;
  flex-direction: column;
  background-color: white;
  box-shadow: 5px 10px 10px #88888842;
}
.headerDropdown {
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 0px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 15px;
  background-color: white;
  box-shadow: 5px 10px 10px #88888842;
}
.actionWrapper {
  position: relative;
  width: fit-content;
  margin: auto;
  cursor: pointer;
  padding: 8px 20px;
}
.actionWrapper:hover .actionButtonsContainer {
  display: flex;
}
.actionButton {
  all: unset;
  padding: 8px 15px;
  cursor: pointer;
}
.actionButton:hover {
  background-color: var(--secondaryBackground);
}
.headerLogoutButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.deleteModalContainer {
  position: relative;
  background-color: white;
  border-radius: 10px;
  text-align: center;
  gap: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 30px 25px;
  margin: auto;
}
.deletModalTitle {
  color: var(--Shade);
  font-weight: 500;
  font-size: 26px;
}
.warningButton {
  all: unset;
  cursor: pointer;
  color: var(--Warning);
  border: 1px solid var(--Warning);
  border-radius: 8px;
  padding: 7px 29px;
}
.centerTableCell {
  text-align: center !important;
}
.paginationContainer {
  width: calc(100% - 40px);
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  height: 55px;
  align-items: center;
  padding: 0 20px;
  background-color: #fff;
}
.paginationButton {
  all: unset;
  padding: 8px;
  cursor: pointer;
}
.filterInputsContainer {
  padding: 20px;
  background-color: var(--Shade);
  margin-top: -10px;
  color: white;
  position: relative;
  display: flex;
  gap: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.filterInputsContainer > * {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
}
.filterInputsContainer:before {
  content: "";
  width: 20px;
  height: 20px;
  background-color: var(--Shade);
  position: absolute;
  top: -4px;
  right: 10px;
  rotate: -45deg;
}
.filterSelectInput:focus-visible textarea:focus-visible,
.filterTextInput:focus-visible textarea:focus-visible {
  outline: none;
  border: 1px solid var(--c300);
  border-radius: 8px;
}
.filterSelectInput {
  padding: 0px 10px;
  color: #2b2828;
  outline: none;
  border: 1px solid var(--c300);
  border-radius: 8px;
  height: 42px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url(./assets/images/arrowDown.svg);
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 18px;
  background-color: white;
}
.filterTextInput {
  padding: 0px 10px;
  color: #2b2828;
  outline: none;
  border: 1px solid var(--c300);
  border-radius: 8px;
  height: 42px;
  background-color: white;
}
.infoSpan {
  display: inline-block;
  visibility: hidden;
  width: 15px;
  height: 15px;
  color: var(--Shade);
  border-radius: 50%;
  font-size: 12px;
  cursor: pointer;
  margin: 5px;
}
.visibleTitle:hover .infoSpan {
  visibility: visible;
  background-color: white;
  transition: visibility 0.5s, background-color 0.5s;
}
.infoText {
  display: none;
}
.infoSpan:hover .infoText {
  display: block;
  position: absolute;
  background-color: white;
  color: var(--Shade);
  right: 0;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 5px;
  z-index: 1;
  width: 200px;
}
.infoSpan {
  position: relative;
}

.approveButton,
.bookedButton {
  all: unset;
  color: var(--acceptance);
  border: 1px solid var(--acceptance);
  border-radius: 8px;
  padding: 5px 20px;
  margin: 2px;
  position: relative;
}
.approveButton:hover,
.bookedButton:hover {
  color: white;
  border: 1px solid var(--acceptance);
  background-color: var(--acceptance);
}
.approveButton:hover.approveButton::after {
  content: "Approve";
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  background-color: #1a5388;
  color: white;
  font-size: 11px;
  border-radius: 15px;
  padding: 1px 3px;
}
.bookedButton:hover.bookedButton::after {
  content: "Booked";
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  background-color: #1a5388;
  color: white;
  font-size: 11px;
  border-radius: 15px;
  padding: 1px 3px;
}
.canceledButton,
.rejectButton {
  all: unset;
  color: var(--c400);
  border: 1px solid var(--c400);
  border-radius: 8px;
  padding: 5px 20px;
  margin: 2px;
  position: relative;
}
.canceledButton:hover,
.rejectButton:hover {
  color: white;
  border: 1px solid var(--Warning);
  background-color: var(--Warning);
}
.canceledButton:hover.canceledButton::after {
  content: "Canceled";
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  background-color: #1a5388;
  color: white;
  font-size: 11px;
  border-radius: 15px;
  padding: 1px 0px;
}
.rejectButton:hover.rejectButton::after {
  content: "Reject";
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  background-color: #1a5388;
  color: white;
  font-size: 11px;
  border-radius: 15px;
  padding: 1px 0px;
}
.approvedText {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background: var(--greenBackground);
  border-radius: 8px;
  color: var(--greenText);
  padding: 7px 30px;
  width: 70px;
  margin: auto;
}
.rejectedText {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background: var(--redBackground);
  border-radius: 8px;
  color: var(--Warning);
  padding: 7px 30px;
  width: 70px;
  margin: auto;
}
.sendEmailButton {
  all: unset;
  cursor: pointer;
  color: var(--Primary);
  border: 1px solid var(--Primary);
  border-radius: 8px;
  padding: 5px 29px;
  width: 70px;
}
.adminBlankPageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  color: var(--Shade);
}
.archiveButton {
  all: unset;
  cursor: pointer;
  margin: 2px;
  padding: 5px 25px;
  font-weight: bold;
  color: var(--c500);
  background-color: white;
  border-radius: 20px;
}
.notArchivedButton {
  all: unset;
  cursor: pointer;
  margin: 2px;
  padding: 5px 25px;
  color: var(--c500);
}
.goToLoginButton {
  all: unset;
  cursor: pointer;
  background-color: white;
  color: var(--acceptance);
  border-radius: 8px;
  padding: 7px 29px;
}
.userPageTitleContainer {
  padding: 30px 30px;
  background-color: var(--Primary);
  color: white;
  font-size: 20px;
  position: relative;
  overflow: hidden;
}
.userPageTitleContainer > h1 {
  font-weight: 600;
  z-index: 1;
  position: inherit;
}
.wave1 {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
}
.wave2 {
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 0;
}
.userCoursesPageContainer {
  display: grid;
  grid-template-columns: 340px 1fr;
  padding: 30px 30px;
}
.filterSearchInputContainer {
  padding: 15px;
}
.userFilterInputsWrapper {
  background-color: var(--c100);
  margin: 20px 30px 0px 0px;
  border-radius: 8px;
}
.userFilterInputsContainer {
  border-top: 1px solid var(--c200);
  padding: 10px 15px;
}
.userFilterGroup {
  padding: 5px 0px;
}
input[type="text"].userSearchInput {
  height: 40px;
  width: 230px;
  background-color: white;
  border-radius: 29px;
  border: none;
  color: var(--c400);
  padding-left: 40px;
}
.filterInputTitle {
  margin-bottom: 5px;
  font-size: 16px;
  color: var(--Shade);
  font-weight: 500;
}
.css-14pt78w-MuiSlider-rail {
  background-color: var(--c200) !important;
  opacity: 1 !important;
  height: 6px !important;
}
.priceRangeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--Shade);
  font-size: 15px;
  font-weight: 600;
}
.css-ahj2mt-MuiTypography-root {
  font-size: 14px !important;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  padding: 7px 9px !important;
}
.selectedTap {
  all: unset;
  cursor: pointer;
  margin: 2px;
  padding: 5px 25px;
  font-weight: bold;
  color: var(--c500);
  background-color: white;
  border-radius: 20px;
}
.notSelectedTap {
  all: unset;
  cursor: pointer;
  margin: 2px;
  padding: 5px 25px;
  color: var(--c500);
}
.userPageContent {
  display: grid;
  justify-content: start;
  align-items: stretch;
  justify-items: stretch;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin: 20px 0px;
}
.courseCard {
  border: 1px solid var(--c200);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.courseLevel {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.courseName {
  margin-top: 10px;
  font-size: 17px;
  font-weight: 600;
  color: var(--c600);
}
.courseDetails {
  padding: 15px;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.courseDescription {
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.3px;
  color: var(--c600);
  margin-top: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.bottomCardContainer {
  background-color: var(--c100);
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 0px 7px 7px;
}
.approvedBottomCardContainer {
  height: 34px;
  background-color: var(--acceptance);
  color: white;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 7px 7px;
}
.rejectedBottomCardContainer {
  height: 34px;
  background-color: var(--Warning);
  color: white;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 7px 7px;
}
.pendingBottomCardContainer {
  height: 34px;
  background-color: var(--Yellow);
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 7px 7px;
}
.cardHoursContainer {
  display: flex;
  align-items: center;
  color: var(--c500);
  gap: 5px;
  font-size: 13px;
  margin-top: 10px;
}
.levelCourse {
  color: var(--Primary);
  position: relative;
  padding-left: 17px;
}
.levelCourse::before {
  padding: 6px;
  content: "";
  background-color: var(--before-color);
  position: absolute;
  left: 0;
  bottom: 2px;
  border-radius: 50%;
}
.courseCardPrice {
  font-size: 17px;
  font-weight: 700;
  color: var(--c600);
}
.courseCardBookButton {
  all: unset;
  cursor: pointer;
  background-color: var(--Shade2);
  color: white;
  font-weight: 500;
  font-size: 15px;
  padding: 8px 35px;
  border-radius: 4px;
}
.contentContainer {
  display: flex;
  flex-direction: column;
}
.css-nnid7-MuiSlider-valueLabel {
  background-color: transparent !important;
  top: 0 !important;
  color: var(--c500) !important;
  font-size: 11px !important;
  transform: translateY(65%) scale(1) !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  border: 0 !important;
}
.modal {
  background-color: #fff;
  border-radius: 8px;
  left: 50%;
  right: 50%;
  top: 40%;
  box-shadow: 13px 13px 16px 100vw #0000002b;
  padding: 20px;
}
.modal::before {
  content: "";
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: -1;
}
.modal .closeButton {
  all: unset;
  position: absolute;
  top: 5px;
  right: 15px;
  font-size: 23px;
  color: var(--Warning);
}